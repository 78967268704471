<template>
  <v-container class="align-content-center">
    <v-row class="justify-center">
      <v-col cols="12" md="6">
        <v-card>
          <v-container>
            <!-- Title -->
            <v-row>
              <v-col class="justify-center">
                <div class="display-1	text-center">
                  Scanner
                </div>
              </v-col>
            </v-row>

            <!-- Select Event-->
            <v-row>
              <v-col class="justify-center">
                <v-select
                  v-model="chosenEvent"
                  :items="events"
                  item-text="name"
                  return-object
                  label="Select event to scan for"
                  required
                  outlined
                  rounded
                  clearable
                  class="mb-n10"
                ></v-select>
              </v-col>
            </v-row>

            <!-- points and age -->
            <v-row v-if="chosenEvent" class="mt-2 mr-2">
              <v-spacer></v-spacer>
              <span v-if="chosenEvent.over18" class="mr-3">
                <v-img
                  class="mb-1"
                  :src="require('@/assets/age.svg')"
                  width="30px"
                ></v-img>
              </span>
              <span>
                +{{ chosenEvent.points
                }}<v-icon class="pb-1">mdi-cards-diamond</v-icon>
              </span>
            </v-row>

            <!-- Scanner-->
            <v-row v-if="chosenEvent">
              <v-col class="justify-center">
                <qrcode-stream
                  style="height:300px"
                  @decode="onDecode"
                ></qrcode-stream>
              </v-col>
            </v-row>

            <v-divider v-if="chosenEvent"></v-divider>

            <!-- Person just scanned Title-->
            <v-row v-if="chosenEvent && justScannedMember">
              <v-col class="justify-center">
                <div class="headline text-center">
                  Just Scanned
                </div>
              </v-col>
              <v-fab-transition>
                <v-btn
                  fab
                  absolute
                  right
                  color="error"
                  icon
                  @click="justScannedID = null"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-fab-transition>
            </v-row>

            <!-- Person Just scanned Member -->
            <v-row v-if="chosenEvent && justScannedID">
              <v-col class="d-inline-flex justify-center">
                <v-card class="pa-3">
                  <v-row>
                    <v-col class="d-inline-flex justify-center">
                      <Avatar :size="100" :user="justScannedAttendee" />
                    </v-col>
                  </v-row>
                  <div class="display-1	text-center">
                    {{ justScannedMember.FirstName }}
                    {{ justScannedMember.Surname }}
                  </div>
                  <div v-if="dupe" class="red--text pb-0 title text-center">
                    Scanned again at {{ dupeDate.toLocaleTimeString() }}
                  </div>

                  <v-card-subtitle
                    :class="[
                      'py-0',
                      'text-center',
                      calculate_age(justScannedMember.DoB.toDate())
                        ? ''
                        : 'red--text'
                    ]"
                  >
                    Age:
                    {{ calculate_age(justScannedMember.DoB.toDate()) }} years
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>

            <v-divider v-if="chosenEvent"></v-divider>

            <!-- Attendance -->
            <v-row v-if="chosenEvent">
              <v-col>
                <v-list>
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="headline">
                            Attendance: {{ membersAttendedChosenEventCount }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-divider></v-divider>

                    <v-list-item
                      v-for="attendee in membersAttendedChosenEvent"
                      :key="attendee.Login"
                    >
                      <v-list-item-content>
                        <v-row>
                          <v-col
                            cols="2"
                            class="pr-0 d-inline-flex justify-center"
                          >
                            <Avatar :user="attendee" :size="35" />
                          </v-col>
                          <v-col class="pl-0 text-left title">
                            {{ members[attendee.id].FirstName }}
                            {{ members[attendee.id].Surname }}
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "@/plugins/firebase";
import { QrcodeStream } from "vue-qrcode-reader";
import Avatar from "@/components/Profile/Avatar";

export default {
  fiery: true,
  components: {
    QrcodeStream,
    Avatar
  },
  data() {
    return {
      dupe: false,
      justScannedID: null,
      chosenEvent: null,
      dupeDate: null,
      underage: false,
      events: this.$fiery(db.collection("Events"), {
        key: "id",
        // start date of event is bigger than yesterday
        query: event =>
          event
            .where(
              "date",
              ">",
              new Date(new Date().setDate(new Date().getDate() - 1))
            )
            .orderBy("date", "desc")
      }),
      attendees: this.$fiery(db.collection("Attendance"), {
        key: "id",
        sub: {
          EventsAttended: {
            query: events => events,
            key: "id",
            map: true
          }
        }
      }),
      members: this.$fiery(db.collection("Members"), {
        key: "id",
        map: true
      })
    };
  },
  computed: {
    justScannedMember() {
      if (this.justScannedID) {
        return this.$fiery(db.collection("Members").doc(this.justScannedID));
      } else {
        return null;
      }
    },
    justScannedAttendee() {
      if (this.justScannedID) {
        return this.$fiery(db.collection("Attendance").doc(this.justScannedID));
      } else {
        return null;
      }
    },
    membersAttendedChosenEventCount() {
      return this.membersAttendedChosenEvent.length;
    },
    membersAttendedChosenEvent() {
      if (this.chosenEvent) {
        // find all attendees that have attended and event with the same id as the chosen event
        return this.attendees.filter(
          member => member.EventsAttended[this.chosenEvent.id]
        );
      } else {
        return [];
      }
    }
  },
  methods: {
    calculate_age(dob) {
      var diff_ms = Date.now() - dob.getTime();
      var age_dt = new Date(diff_ms);

      return Math.abs(age_dt.getUTCFullYear() - 1970);
    },
    onDecode(decodeString) {
      if (decodeString) {
        const age = this.calculate_age(this.members[decodeString].DoB.toDate());
        if (this.chosenEvent.over18 && age < 18) {
          this.justScannedID = decodeString;
          this.underage = true;
        } else {
          this.underage = false;
          const userScan = db.collection("Attendance").doc(decodeString);
          const attendance = userScan
            .collection("EventsAttended")
            .doc(this.chosenEvent.id);
          attendance.get().then(doc => {
            if (doc.exists) {
              this.dupeDate = doc.data().scanned.toDate();
              this.dupe = true;
            } else {
              this.dupe = false;
              this.dupeDate = null;

              attendance.set({
                scanned: new Date(),
                points: this.chosenEvent.points ? this.chosenEvent.points : 0
              });
            }
            this.justScannedID = decodeString;
          });
        }
      }
    }
  }
};
</script>
